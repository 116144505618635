img {
  border: 0;
}

.hero-headline {
  padding-top: 20%;
}

#home-about {
  .about-copy {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
  }
}

#home-products {
  
  .section-header {
    display: block;
    float: left;

    h1 {
      width: 300px;
      margin-top: 45%;
    }
  }

  #products-index {
    display: block;
    float: left;
  }
}

.banner-headline {
  padding-top: 230px;
}

#site-footer {
  
  .brand {
    width: 50%;
    float: left;
    padding-top: 48px;
  }

  .copyright {
    float: right;
    padding-top: 56px;
  }
}

.post-content .container {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .post-main {
    display: block;
  }
}

.navigation-prev {
  width: 33%;
  display: block;
  float: left;
}

.post-remark {
  width: 33%;
  display: block;
  float: left;
}

.navigation-next {
  width: 33%;
  display: block;
  float: right;
}

#back {
  .hint {
    float: left;
  }
}